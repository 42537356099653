import React from 'react'

import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentPaymentTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderNL = (
    <>
      <p>Bij Shop Up North dragen we jouw veiligheid héél hoog in het vaandel. Daarom bieden we jou de betaalmethoden van MultiSafepay &amp; Paypal aan. Beide leveranciers hebben van veilige betalingen hun core business gemaakt, dus je kan met een gerust hart online betalen.</p>
      <p>Concreet kan je betalen met Bancontact/MisterCash, VISA of MasterCard, PayPal, Maestro &amp; iDEAL.</p>
    </>
  )

  const renderEN = (
    <>
      <p>Our webshop is protected by different secure payment methods. And <i>safe</i> means that no one can see or abuse your data. So maximum security.</p>
      <p>Shop Up North uses the payment methods provided by MultiSafepay and Paypal.</p>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentPaymentTemplate
